'use strict'


routes.cart = function(id, action) {
  
  
    mount('loading');
    var addToCartItem={}
         recipeService.fetchRecommendedRecipes()
        .then(function(recipes) {
          var recommendedRecipies={}
             for (var key in recipes ){
               recommendedRecipies[key]=recipes[key];
             }

                   if (id){
                    recipeService.fetchRecipeById(id)
        .then(function(recipe) {
          addToCartItem=recipe;
                   mount('cart', { recipes: recommendedRecipies,addToCart:addToCartItem});
        });
                   }
                   else{
                               mount('cart', { recipes: recommendedRecipies,addToCart:addToCartItem});         
                   }
             
          
   
         });
    
}